// Generated by Framer (241a4b4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["I864:5360;51:1088", "HFc8ql5Ai"];

const variantClassNames = {"I864:5360;51:1088": "framer-v-1cmt9tb", HFc8ql5Ai: "framer-v-c5dal3"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {left: "I864:5360;51:1088", right: "HFc8ql5Ai"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, nxKpINyRp: tap ?? props.nxKpINyRp, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "I864:5360;51:1088"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, nxKpINyRp, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "I864:5360;51:1088", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1buof57 = activeVariantCallback(async (...args) => {
if (nxKpINyRp) {
const res = await nxKpINyRp(...args);
if (res === false) return false;
}
setVariant("HFc8ql5Ai")
})

const onTap1gyoeoy = activeVariantCallback(async (...args) => {
if (nxKpINyRp) {
const res = await nxKpINyRp(...args);
if (res === false) return false;
}
setVariant("I864:5360;51:1088")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-TVJGS", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1cmt9tb", className)} data-border data-framer-name={"left"} data-highlight layoutDependency={layoutDependency} layoutId={"I864:5360;51:1088"} onTap={onTap1buof57} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "rgb(218, 95, 111)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12, ...style}} {...addPropertyOverrides({HFc8ql5Ai: {"data-framer-name": "right", onTap: onTap1gyoeoy}}, baseVariant, gestureVariant)}><motion.div className={"framer-ziw7gc"} data-framer-name={"Button"} layoutDependency={layoutDependency} layoutId={"I864:5360;51:1089"} style={{backgroundColor: "rgb(218, 95, 111)", borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%"}}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-TVJGS [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-TVJGS .framer-1k5zat { display: block; }", ".framer-TVJGS .framer-1cmt9tb { align-content: flex-start; align-items: flex-start; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: flex-start; overflow: hidden; padding: 2px 2px 2px 2px; position: relative; width: 36px; will-change: var(--framer-will-change-override, transform); }", ".framer-TVJGS .framer-ziw7gc { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 16px); position: relative; width: 16px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-TVJGS .framer-1cmt9tb { gap: 0px; } .framer-TVJGS .framer-1cmt9tb > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-TVJGS .framer-1cmt9tb > :first-child { margin-left: 0px; } .framer-TVJGS .framer-1cmt9tb > :last-child { margin-right: 0px; } }", ".framer-TVJGS.framer-v-c5dal3 .framer-1cmt9tb { justify-content: flex-end; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 36
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"HFc8ql5Ai":{"layout":["fixed","auto"]}}}
 * @framerVariables {"nxKpINyRp":"tap"}
 */
const FramerkmV978VJf: React.ComponentType<Props> = withCSS(Component, css, "framer-TVJGS") as typeof Component;
export default FramerkmV978VJf;

FramerkmV978VJf.displayName = "Toggle";

FramerkmV978VJf.defaultProps = {height: 20, width: 36};

addPropertyControls(FramerkmV978VJf, {variant: {options: ["I864:5360;51:1088", "HFc8ql5Ai"], optionTitles: ["left", "right"], title: "Variant", type: ControlType.Enum}, nxKpINyRp: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerkmV978VJf, [])